import { injectable } from 'inversify';
import { UnsubscribedCategory } from '../../types/UnsubscribedCategory';
import { UnsubscribedCategoriesProvider } from './UnsubscribedCategoriesProvider';

@injectable()
export class MutableUnsubscribedCategoriesProvider implements UnsubscribedCategoriesProvider {
  constructor(
    private _categories: Array<UnsubscribedCategory> = [
      { id: '123', name: 'TestCategory', description: 'Test unsubscribed category' }
    ]
  ) {}

  getCategories(): Array<UnsubscribedCategory> {
    return this._categories;
  }

  setCategories(categories: Array<UnsubscribedCategory>): this {
    this._categories = categories;

    return this;
  }
}
