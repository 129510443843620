import { injectable } from 'inversify';
import { TrackerDetails } from '../reporter/Reporter';
import { ActionTracker } from './ActionTracker';

@injectable()
class FakeActionTracker implements ActionTracker {
  track(category: string, action: string, details: TrackerDetails): void {
    // noop
  }
}

export { FakeActionTracker };
