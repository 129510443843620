import { injectable } from 'inversify';
import { TrackerDetails } from '../reporter/Reporter';
import { PerformanceTracker } from './PerformanceTracker';

@injectable()
class FakePerformanceTracker implements PerformanceTracker {
  start(method: string): void {
    // No-op
  }

  end(method: string): TrackerDetails | null {
    // No-op
    return { duration: 1 };
  }
}

export { FakePerformanceTracker };
