import { injectable } from 'inversify';
import { Reporter, TrackerDetails } from '../reporter/Reporter';
import { ActionTracker } from './ActionTracker';

@injectable()
class LeanplumActionTracker implements ActionTracker {
  constructor(private reporter: Reporter) {}

  track(category: string, action: string, details: TrackerDetails): void {
    this.reporter.track(`${category} - ${action}`, details);
  }
}

export { LeanplumActionTracker };
