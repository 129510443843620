import { injectable } from 'inversify';
import { Route } from 'vue-router';
import { Logger } from '../../diagnostics/Logger/Logger';
import { LatencyEvent } from '../LatencyEvent';
import { Reporter, TrackerDetails } from '../reporter/Reporter';
import { PageLoadTracker } from './PageLoadTracker';

interface PageLoadTrackerDetails extends TrackerDetails {
  route: string | null;
  uiLatency: number | undefined;
  networkLatency: number | undefined;
}

@injectable()
class LeanplumPageLoadTracker implements PageLoadTracker {
  private _hasAlreadyLoaded: boolean = false;

  constructor(private reporter: Reporter, private logger: Logger) {}

  track(currentRoute: Route): void {
    if (!this._hasAlreadyLoaded && !!window.performance) {
      // Mark the current time as a performance entry where PAGE_LOAD is the name,
      // and startTime is the total millis from navigationStart.
      window.performance.mark(LatencyEvent.PAGE_LOAD);

      const performanceEntries: Array<PerformanceEntry> = window.performance.getEntriesByName(LatencyEvent.PAGE_LOAD);

      if (performanceEntries.length === 0) {
        // Log error instead of throwing.
        this.logger.log('Page load Performance Entry was not found.');

        return;
      }

      const duration: number = performanceEntries[0].startTime;

      const details: PageLoadTrackerDetails = {
        route: currentRoute.name || null,
        duration,
        ...this.getLatencyDetails()
      };

      // We only track the first occurence of page load, since multiple components
      // will try to track this when mounted().
      this._hasAlreadyLoaded = true;

      // And we clear the entry, just for the sake of neatness.
      window.performance.clearMarks(LatencyEvent.PAGE_LOAD);

      this.reporter.track(LatencyEvent.PAGE_LOAD, details);
    }
  }

  private getLatencyDetails(): { networkLatency: number | undefined; uiLatency: number | undefined } {
    let networkLatency: number | undefined;
    let uiLatency: number | undefined;

    const navigationEntries: Array<PerformanceEntry> = window.performance.getEntriesByType('navigation');

    // Safari does not support navigation entries at all.
    if (navigationEntries.length > 0) {
      const navigationTiming: PerformanceNavigationTiming = navigationEntries[0] as PerformanceNavigationTiming;
      networkLatency = navigationTiming.responseEnd - navigationTiming.fetchStart;
      uiLatency = navigationTiming.domComplete - navigationTiming.responseEnd;
    }

    return { networkLatency, uiLatency };
  }
}

export { LeanplumPageLoadTracker };
