import { injectable } from 'inversify';
import { RpcRequestTracker } from './RpcRequestTracker';

@injectable()
class FakeRpcRequestTracker implements RpcRequestTracker {
  private started: Array<string> = [];
  private ended: Array<string> = [];

  start(method: string): void {
    this.started.push(method);
  }

  end(method: string): void {
    this.ended.push(method);
  }

  didTrack(method: string): boolean {
    return this.started.indexOf(method) > -1 && this.ended.indexOf(method) > -1;
  }

  clear(): void {
    this.started = [];
    this.ended = [];
  }
}

export { FakeRpcRequestTracker };
