import { injectable } from 'inversify';

@injectable()
export abstract class AccountInfoProvider {
  abstract getName(): string | null;
  abstract getEmail(): string;
  abstract getAccountId(): string;
  abstract isLpAdmin(): boolean;
  abstract getCompanyAccountId(): string;
}
