import { injectable } from 'inversify';
import { Dictionary } from '../../types/Dictionary';
import { ServiceLocator } from './ServiceLocator';

@injectable()
export class FakeServiceLocator implements ServiceLocator {
  // Difference between Fake and Static Service Locators is that the Fake doesn't require all services to be mapped.
  constructor(private _serviceUrls: Dictionary<string>) {}

  get(service: ServiceLocator.Service): string {
    return this._serviceUrls[service];
  }
}
