import { injectable } from 'inversify';
import { CsrfTokenProvider } from './CsrfTokenProvider';

@injectable()
export class StaticCsrfTokenProvider implements CsrfTokenProvider {
  constructor(private token: string) {}

  getToken(): string {
    return this.token;
  }
}
