import { injectable } from 'inversify';
import { Environment } from '../../constants/Environment';

@injectable()
export abstract class EnvironmentProvider {
  abstract get isLocal(): boolean;
  abstract get isStaging(): boolean;
  abstract get isQA(): boolean;
  abstract get isProduction(): boolean;
  abstract getEnvironment(): Environment;
}
