export enum Permission {
  VIEW_AUDIENCE = 'view-audience',
  DRAFT_APP_ASSETS = 'draft-app-assets',
  DRAFT_CAMPAIGN = 'draft-campaign',
  PUBLISH_APP = 'publish-app',
  PUBLISH_AUDIENCE = 'publish-audience',
  PUBLISH_CAMPAIGN = 'publish-campaign',
  MANAGE_APP = 'manage-app',
  MANAGE_AUDIENCE = 'manage-audience',
  MANAGE_CAMPAIGN = 'manage-campaign',
  MANAGE_DATA_CONTROL = 'manage-data-control'
}
