import { injectable } from 'inversify';
import { AccountInfoProvider } from './AccountInfoProvider';

@injectable()
export class StaticAccountInfoProvider implements AccountInfoProvider {
  constructor(
    private _name: string | null,
    private _email: string,
    private _accountId: string,
    private _isLpAdmin: boolean,
    private _companyAccountId: string
  ) {}

  getName(): string | null {
    return this._name;
  }

  getEmail(): string {
    return this._email;
  }

  getAccountId(): string {
    return this._accountId;
  }

  isLpAdmin(): boolean {
    return this._isLpAdmin;
  }

  getCompanyAccountId(): string {
    return this._companyAccountId;
  }
}
