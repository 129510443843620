import { injectable } from 'inversify';

interface TrackerDetails {
  duration?: number;
  [k: string]: number | string | boolean | undefined | null;
}

@injectable()
abstract class Reporter {
  abstract track<T extends TrackerDetails>(event: string, details: T): Promise<void>;
}

export type { TrackerDetails };
export { Reporter };
