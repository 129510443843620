import Axios from 'axios';
import { inject, injectable } from 'inversify';
import { AccountInfoProvider } from '../providers/accountInfo/AccountInfoProvider';
import { CsrfTokenProvider } from '../providers/csrfToken/CsrfTokenProvider';
import { Company, CompanyFetcher } from './CompanyFetcher';

@injectable()
class LiveCompanyFetcher implements CompanyFetcher {
  private companies: Array<Company>;

  constructor(
    @inject(CsrfTokenProvider)
    private csrfTokenProvider: CsrfTokenProvider,
    @inject(AccountInfoProvider)
    private accountInfo: AccountInfoProvider
  ) {}

  async getAllCompanies(): Promise<Array<Company>> {
    if (this.companies) {
      return this.companies;
    }

    let queryString: string = '';
    if (this.accountInfo.isLpAdmin()) {
      queryString = `&email=${this.accountInfo.getEmail()}`;
    }

    return new Promise(async (resolve, reject) => {
      Axios.post(
        `/dashboard/settings/ajax?action=listCompanies${queryString}`,
        `_c=${encodeURIComponent(this.csrfTokenProvider.getToken())}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(({ data }) => {
        if (!data.success) {
          reject({});
        } else {
          this.companies = data.companies;
          resolve(this.companies);
        }
      }, reject);
    });
  }
}

export { LiveCompanyFetcher };
