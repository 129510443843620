import { injectable } from 'inversify';

@injectable()
abstract class RpcCredentials {
  abstract getEmail(): string;
  abstract getAppId(): string;
  abstract getCsrfToken(): string;
}

export { RpcCredentials };
