// tslint:disable:no-any

import { injectable } from 'inversify';

@injectable()
abstract class Logger {
  abstract log(...messages: Array<any>): void;
}

export { Logger };
