import { injectable } from 'inversify';
import { Whitelist } from '../../constants/Whitelist';

@injectable()
export abstract class WhitelistProvider {
  abstract isWhitelistedFor(whitelist: Whitelist): boolean;
  abstract isWhitelistedForEvery(whitelists: Array<Whitelist>): boolean;

  /**
   * Enables Whitelist override for Leanplum Admins.
   * @param whitelist
   */
  abstract enableOverride(whitelist: Whitelist): void;

  /**
   * Disables Whitelist override for Leanplum Admins.
   * @param whitelist
   */
  abstract disableOverride(whitelist: Whitelist): void;

  /**
   * Changes Whitelist override for Leanplum Admins to provided value.
   * @param whitelist
   */
  setOverride(whitelist: Whitelist, enabled: boolean): void {
    if (enabled) {
      this.enableOverride(whitelist);
    } else {
      this.disableOverride(whitelist);
    }
  }

  /**
   * Returns true if the whitelist is overriden.
   * @param whitelist
   */
  abstract isOverriden(whitelist: Whitelist): boolean;

  /**
   * Returns true if there are any whitelists overriden.
   */
  abstract hasOverrides(): boolean;

  /**
   * Returns the overriden whitelists.
   */
  abstract getOverrides(): Array<Whitelist>;
}
