import { injectable } from 'inversify';
import { Logger } from '../../diagnostics/Logger/Logger';
import { LatencyEvent } from '../LatencyEvent';
import { PerformanceTracker } from '../performanceTracker/PerformanceTracker';
import { Reporter, TrackerDetails } from '../reporter/Reporter';
import { RpcRequestTracker } from './RpcRequestTracker';

interface RpcRequestTrackerDetails extends TrackerDetails {
  rpcName: string;
}

@injectable()
class LeanplumRpcRequestTracker implements RpcRequestTracker {
  constructor(private reporter: Reporter, private performanceTracker: PerformanceTracker, private logger: Logger) {}

  start(method: string): void {
    this.performanceTracker.start(method);
  }

  end(method: string): void {
    const perfDetails = this.performanceTracker.end(method);
    if (!perfDetails) {
      this.logger.log(`Latency Tracking for RPC ${method} failed.`);

      return;
    }

    const details: RpcRequestTrackerDetails = {
      rpcName: method,
      duration: perfDetails.duration
    };
    this.reporter.track(LatencyEvent.RPC_REQUEST, details);
  }
}

export { LeanplumRpcRequestTracker };
