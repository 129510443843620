import { injectable } from 'inversify';
import { ServiceLocator } from './ServiceLocator';

@injectable()
export class StaticServiceLocator implements ServiceLocator {
  constructor(
    private _serviceUrls: Record<ServiceLocator.Service, string>
  ) { }

  get(service: ServiceLocator.Service): string {
    return String(this._serviceUrls[service]);
  }
}
