import { injectable } from 'inversify';
import { Company, CompanyFetcher } from './CompanyFetcher';

@injectable()
class StaticCompanyFetcher implements CompanyFetcher {
  constructor(
    private companies: Array<Company>
  ) {}

  async getAllCompanies(): Promise<Array<Company>> {
    return this.companies;
  }
}

export { StaticCompanyFetcher };
