/**
 * Writes the specified text string to the system clipboard.
 *
 * @param text text to be written to the clipboard.
 *
 * @returns
 * Promise which is resolved once the clipboard's contents have been updated.
 * The promise is rejected if the caller does not have permission to write to
 * the clipboard, or if the opreration fails for any other reason.
 */
export function copyToClipboard(text: string): Promise<void> {
  return (
    navigator.clipboard
      ? navigator.clipboard.writeText(text).catch(() => copyToClipboardFallback(text))
      : copyToClipboardFallback(text)
  );
}

/**
 * Alternative/fallback implementation for browsers that don't support Clipboard API.
 *
 * @description
 * In order to copy the text to the clipboard it creates a dummy HTMLTextAreaElement,
 * places the text inside it, then selects everything and executes the command 'copy'.
 *
 * @see https://stackoverflow.com/a/30810322
 */
function copyToClipboardFallback(text: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const textarea: HTMLTextAreaElement = document.createElement('textarea');

    Object.assign(textarea.style, {
      // Prevent scrolling to bottom of the page in some browsers.
      position: 'fixed',
      bottom: '0',
      left: '0',
      // Avoid flash of the textarea (if rendered for any reason).
      background: 'transparent',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
      zIndex: '-1'
    } as Partial<CSSStyleDeclaration>);

    textarea.setAttribute('value', text);
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      // NOTE:  Only returns `true` if part of a user interaction.
      // Returns `false` when the command is unsupported/disabled.
      return (
        document.execCommand('copy') === false
          ? reject('The command "copy" is unsupported or disabled.')
          : resolve()
      );
    } catch (ex) {
      // Security exception that might be thrown in some browsers.
      return reject(ex);
    } finally {
      document.body.removeChild(textarea);
    }
  });
}
