// Whitelists sorted alphabetically by name.
export enum Whitelist {
  AD_HOC_SEGMENT_PICKER_RULES = 'ad-hoc-segment-picker-rules',
  ALTERNATIVE_IMAGE_IAM_IMPL = 'alternative-image-iam-impl',
  APNS_AUTH_KEYS = 'apns-auth-keys',
  AUDIENCE_DASHBOARD_DEV = 'audience-dashboard-dev', // generic whitelist for ISAU features under development.
  AUDIENCE_PARTIAL_DATES = 'audience-partial-dates',
  AUDIENCE_RULE_LIMITS = 'audience-rule-limits',
  BETA_FEATURES = 'cc-enable-beta-features',
  CAMPAIGN_EXIT_EVENTS = 'campaign-exit-events',
  CAMPAIGN_VALIDATION = 'campaign-validation',
  CAMPAIGNS_DEV = 'campaigns-dev', // generic whitelist for CAMP features under development.
  CUSTOM_TIMEZONES = 'custom-timezones',
  DASHBOARD_NOTIFICATIONS = 'dashboard-notifications',
  DELAY_OVERRIDES = 'cc-enable-delay-overrides',
  DISABLE_CLASSIC_CAMPAIGN_ANALYTICS = 'disable-classic-campaign-analytics',
  DISABLE_CLASSIC_MESSAGE_CREATION = 'disable-classic-message-creation',
  DISABLE_CLASSIC_CAMPAIGN_CREATION = 'disable-classic-campaign-creation',
  DISABLE_CLASSIC_MESSAGE_CERT_VALIDATION = 'disable-messages-push-certificate-validation',
  EMAIL_WYSIWYG_DEFAULT = 'email-wysiwig-default',
  HIDE_UNKNOWN_ERRORS = 'hide-unknown-errors',
  HIGHER_RECENT_OCCURRENCES_LIMIT = 'incr-occurrences-limit',
  IMMEDIATE_TOP_LEVEL_IN_APP = 'cc-immediate-top-level-in-app',
  IMPERSONATION_VALUES_DEV = 'impersonation-values-dev',
  INTEGRATIONS_DASHBOARD = 'integrations-dashboard',
  IOS_15_RELEVANCE_SCORE = 'ios-15-relevance-score',
  LABELS = 'cc-labels',
  MIPUSH_NOTIFICATIONS = 'mipush-notifications',
  HUAWEI_PUSH_NOTIFICATIONS = 'huawei-push-notifications',
  QA_RECURRENCE = 'cc-qa-recurrence',
  RECENT_OCCURRENCES = 'recent-occurrences',
  REDSHIFT_DATA_EXPORT = 'export-data-redshift-csv',
  SEGMENTATION_API_COMPATIBILITY = 'segmentation-api-compatibility'
}
