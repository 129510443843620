export class KeyboardConstants {
  static ESC_KEY: string = 'Escape';
  static TAB_KEY: string = 'Tab';
  static ENTER_KEY: string = 'Enter';
  static META_KEY: string = 'Meta';
  static BACKSPACE_KEY: string = 'Backspace';
  static DELETE_KEY: string = 'Delete';
  static ARROW_LEFT_KEY: string = 'ArrowLeft';
  static ARROW_UP_KEY: string = 'ArrowUp';
  static ARROW_RIGHT_KEY: string = 'ArrowRight';
  static ARROW_DOWN_KEY: string = 'ArrowDown';
  static FORWARD_SLASH_KEY: string = '/';
  static Z_KEY: string = 'z';
}
