import debounce from 'lodash/debounce';

interface Cancelable {
  cancel(): void;
}

interface DebounceSettings {
  /**
   * Specify invoking on the leading edge of the timeout.
   */
  leading?: boolean;

  /**
   * The maximum time func is allowed to be delayed before it’s invoked.
   */
  maxWait?: number;

  /**
   * Specify invoking on the trailing edge of the timeout.
   */
  trailing?: boolean;
}

/**
 * Method Decorator
 * Debounces a class method.
 * @param wait: amount of ms to wait (default: 100)
 * @param: options: DebounceSettings
 */
function Debounced(wait: number = 100, options?: DebounceSettings): MethodDecorator {
  return <Function>(
    target: object,
    propertyKey: keyof object,
    { value, ...descriptor }: TypedPropertyDescriptor<Function>
  ): TypedPropertyDescriptor<Function> => {
    if (typeof value !== 'function') {
      throw new TypeError('"Debounced" can only decorate class methods.');
    }

    return {
      configurable: true,
      get(this: object): Function {
        const debounced = debounce(value.bind(this), wait, options);

        // Memoizing the debounced method for each instance.
        Reflect.defineProperty(this, propertyKey, {
          ...descriptor,
          value: debounced
        });

        return debounced;
      }
    };
  };
}

namespace Debounced {
  /**
   * Injects debounced.cancel function.
   * @param debouncedMethodKey the name of the debounced class method
   */
  export function Cancel(debouncedMethodKey: string): PropertyDecorator {
    return (target: object, propertyKey: string): void => {
      Reflect.defineProperty(target, propertyKey, {
        configurable: true,
        get(this: { [key: string]: unknown }): Function {
          function isCancelable(method: unknown): method is Cancelable {
            return typeof (method as Cancelable).cancel === 'function';
          }

          const debouncedMethod = this[debouncedMethodKey];

          if (typeof debouncedMethod !== 'function') {
            throw new TypeError(`${debouncedMethodKey} is not a class method.`);
          }
          if (!isCancelable(debouncedMethod)) {
            throw new TypeError(`${debouncedMethodKey} is not a debounced class method.`);
          }

          // Memoizing the debounced.cancel method for each instance.
          Reflect.defineProperty(this, propertyKey, {
            configurable: true,
            writable: true,
            value: debouncedMethod.cancel
          });

          return debouncedMethod.cancel;
        }
      });
    };
  }
}

export { Debounced };
