// tslint:disable-next-line: no-reference
/// <reference path="../global.d.ts" />

import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/*
 * Encapsulates logic for making authenticated requests.
 */
class RequestManager {
  private static authCache: { [key: string]: JwtCache } = {};

  static readonly MAX_RETRIES = 3;
  static readonly TOKEN_MAX_AGE = 30000; // 30 seconds

  // tslint:disable-next-line: no-any
  static get<R, T = any>(url: string, params?: T, options?: RequestOptions): Promise<AxiosResponse<R>> {
    return RequestManager.request<R>({ method: 'GET', url, params }, options);
  }

  // tslint:disable-next-line: no-any
  static post<R, T = any>(url: string, data?: T, options?: RequestOptions): Promise<AxiosResponse<R>> {
    return RequestManager.request<R>({ method: 'POST', url, data }, options);
  }

  // tslint:disable-next-line: no-any
  static put<R, T = any>(url: string, data?: T, options?: RequestOptions): Promise<AxiosResponse<R>> {
    return RequestManager.request<R>({ method: 'PUT', url, data }, options);
  }

  // tslint:disable-next-line: no-any
  static delete<R, T = any>(url: string, data?: T, options?: RequestOptions): Promise<AxiosResponse<R>> {
    return RequestManager.request<R>({ method: 'DELETE', url, data }, options);
  }

  // TODO: extensibility points for manual retry

  static timeoutPromise<T>(fn: () => Promise<T>, timeout: number): Promise<T> {
    return new Promise((resolve, reject) => setTimeout(() => fn().then(resolve, reject), timeout));
  }

  static retry<T>(
    createPromise: () => Promise<T>,
    options: RetryOptions = {
      maxRetries: this.MAX_RETRIES
    }
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const state = {
        retryCount: 0
      };

      // axios error
      // tslint:disable-next-line: no-any
      const attemptRetry = (reason: AxiosError): Promise<any> => {
        state.retryCount++;
        if (state.retryCount < options.maxRetries) {
          // exponential backoff
          // tslint:disable-next-line:no-bitwise
          return this.timeoutPromise(() => createPromise().then(resolve, attemptRetry), 1000 * (1 << state.retryCount));
        } else {
          reject(reason);

          return Promise.resolve();
        }
      };

      createPromise().then(resolve, attemptRetry);
    });
  }

  static clearAuthToken(): void {
    this.authCache = {};
  }

  static async getAuthToken(
    tokenType: AuthTokenType = AuthTokenType.APPLICATION,
    id?: number,
    config?: AxiosRequestConfig
  ): Promise<JsonWebToken> {
    if (tokenType === AuthTokenType.NONE) {
      return '';
    }

    if (id === undefined && tokenType === AuthTokenType.APPLICATION) {
      id = Number(window.Leanplum.APP_ID);
    }

    const tokenKey = `${tokenType}-${id || 0}`;
    const cachedToken = this.authCache[tokenKey];

    if (cachedToken) {
      const tokenAge = Date.now() - cachedToken.requestTime;
      if (tokenAge < RequestManager.TOKEN_MAX_AGE) {
        return Promise.resolve(cachedToken.token);
      }
    }

    const requestTime = Date.now();
    const requestData = RequestManager.getTokenRequestData(tokenType, id);
    const response = await Axios.get(
      '/dashboard/component/access',
      config ? { ...config, params: { ...(config.params || {}), ...requestData } } : { params: requestData }
    );
    const token = response.data;

    // Update the cache.
    this.authCache[tokenKey] = { requestTime, token };

    return token;
  }

  static request<R>(
    config: AxiosRequestConfig,
    options: RequestOptions = {
      tokenType: AuthTokenType.APPLICATION,
      tokenParam: Number(window.Leanplum.APP_ID),
      retry: true
    }
  ): Promise<AxiosResponse<R>> {
    let createRequest;
    const mergedConfig = { ...config, ...options.config };

    if (!options || options.tokenType !== AuthTokenType.NONE) {
      createRequest = async () =>
        RequestManager.getAuthToken(options.tokenType, options.tokenParam, options.config).then(
          (token: JsonWebToken) => {
            const headers = Object.assign({}, mergedConfig.headers, { Authorization: `Bearer ${token}` });
            Object.assign(mergedConfig, { headers });

            return Axios.request<R>(mergedConfig);
          }
        );
    } else {
      createRequest = () => Axios.request<R>(mergedConfig);
    }

    if (!options || options.retry || typeof options.retry === 'undefined') {
      return RequestManager.retry(createRequest);
    } else {
      return createRequest();
    }
  }

  private static getTokenRequestData(
    tokenType: AuthTokenType = AuthTokenType.APPLICATION,
    id?: number
  ): { appId?: number; companyId?: number } {
    if (id === undefined) {
      id = (tokenType === AuthTokenType.APPLICATION) ? Number(window.Leanplum.APP_ID) : 0;
    }

    switch (tokenType) {
      case AuthTokenType.COMPANY:
        return { appId: 0, companyId: id };

      case AuthTokenType.APPLICATION:
        return { appId: id };

      default:
        return {};
    }
  }
}

type JsonWebToken = string;

interface JwtCache {
  requestTime: number;
  token: string;
}

enum AuthTokenType {
  NONE = '',
  ACCOUNT = 'account',
  APPLICATION = 'app',
  COMPANY = 'company'
}

interface RequestOptions {
  config?: AxiosRequestConfig;
  tokenType?: AuthTokenType;
  tokenParam?: number;
  retry?: boolean;
}

interface RetryOptions {
  maxRetries: number;
}

export type { RequestOptions };
export { AuthTokenType, RequestManager };
