import { injectable } from 'inversify';
import { Account } from 'leanplum-lib-legacy';
import { CompanyAccountsProvider } from './CompanyAccountsProvider';

@injectable()
export class StaticCompanyAccountsProvider extends CompanyAccountsProvider {
  constructor(private readonly accounts: Array<Account>) {
    super();
  }

  getAccountNameByEmail(email: string): string | null {
    return this.accounts.find((x) => x.attributes.email === email)?.attributes.name || null;
  }
}
