import { injectable } from 'inversify';
import { ResourceLoadTracker } from './ResourceLoadTracker';

@injectable()
class FakeResourceLoadTracker implements ResourceLoadTracker {
  private trackCalls: number = 0;

  track(): void {
    this.trackCalls++;
  }

  assertHasBeenCalled(): void {
    expect(this.trackCalls).toBeGreaterThan(0);
  }

  assertHasBeenCalledTimes(times: number): void {
    expect(this.trackCalls).toEqual(times);
  }

  wasCalled(): boolean {
    return this.trackCalls > 0;
  }

  clear(): void {
    this.trackCalls = 0;
  }
}

export { FakeResourceLoadTracker };
