// tslint:disable:no-console no-any
import { injectable } from 'inversify';
import { Logger } from './Logger';

@injectable()
class FakeLogger extends Logger {
  private logs: Array<any> = [];

  log(...messages: Array<any>): void {
    this.logs = this.logs.concat(messages);
  }

  get previousLogs(): Array<any> {
    return this.logs;
  }

  clear(): void {
    this.logs = [];
  }
}

export { FakeLogger };
