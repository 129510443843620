/**
 * Util class for accessing typed object content stored as JSON in session storage.
 */
class SessionStorageWrapper<T> {
  private _key: string;

  constructor(key: string) {
    this._key = key;
  }

  get(): T | undefined {
    const valueInStorage: string | null = sessionStorage.getItem(this._key);
    if (valueInStorage === null) {
      return;
    }

    let parsedValue: T;
    try {
      parsedValue = JSON.parse(valueInStorage);
    } catch (e) {
      return;
    }

    return parsedValue;
  }

  set(value: T | undefined): void {
    sessionStorage.setItem(this._key, JSON.stringify(value));
  }

  remove(): void {
    sessionStorage.removeItem(this._key);
  }
}

export { SessionStorageWrapper };
