import { injectable } from 'inversify';
import { UnsubscribedCategory } from '../../types/UnsubscribedCategory';
import { UnsubscribedCategoriesProvider } from './UnsubscribedCategoriesProvider';

@injectable()
export class StaticUnsubscribedCategoriesProvider implements UnsubscribedCategoriesProvider {
  constructor(private readonly _categories: Array<UnsubscribedCategory>) {}

  getCategories(): Array<UnsubscribedCategory> {
    return this._categories;
  }
}
