import memoize from 'lodash/memoize';

function Memoize(): MethodDecorator {
  // tslint:disable-next-line:no-any
  return (target: any, functionName: string, descriptor: PropertyDescriptor): void => {
    if (typeof descriptor.value !== 'function') {
      throw new TypeError('"Memoize" can only decorate class methods.');
    }

    target[functionName] = memoize(target[functionName]);
  };
}

export { Memoize };
