import { injectable } from 'inversify';
import { TrackerDetails } from '../reporter/Reporter';

@injectable()
abstract class PerformanceTracker {
  /**
   * Initiate a new performance measurement.
   *
   * @param method Unique identifier to use for the created measurement.
   */
  abstract start(method: string): void;

  /**
   * Complete a performance measurement.
   *
   * @param method Unique identifier of the measurement to be completed.
   * @returns a tracker details if the measurement was successfull, null otherwise.
   */
  abstract end(method: string): TrackerDetails | null;
}

export { PerformanceTracker };
