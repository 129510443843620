import { injectable } from 'inversify';

@injectable()
export abstract class ServiceLocator {
  abstract get(service: ServiceLocator.Service): string;
}

export namespace ServiceLocator {
  export enum Service {
    BILLING = 'billing',
    CAMPAIGN_API = 'campaign-api',
    CONTENT_MANAGEMENT = 'content-management',
    DASHBOARD_NOTIFICATIONS = 'dashboard-notifications',
    ORGANIZATION = 'organization',
    PARTNERS = 'partners',
    SSO = 'sso',
    TEAMS = 'teams'
  }
}
