import { injectable } from 'inversify';
import { Reporter, TrackerDetails } from './Reporter';

@injectable()
class FakeReporter implements Reporter {
  private _reports: Array<{ event: string; details: TrackerDetails }> = [];

  async track<T extends TrackerDetails>(event: string, details: T): Promise<void> {
    this._reports.push({ event, details });
  }

  get reports(): Array<{ event: string; details: TrackerDetails }> {
    return this._reports;
  }

  clear(): void {
    this._reports = [];
  }
}

export { FakeReporter };
