import { injectable } from 'inversify';

@injectable()
abstract class AppInfoProvider {
  abstract getId(): string;
  abstract getName(): string;
  abstract getUniqueKey(): string;
  abstract getCompanyId(): number;
  abstract getAccessKeys(): Array<AppInfoProvider.AccessKey>;
  abstract getEmailAttribute(): string | undefined;
  abstract getEnabledForCompanyLevelUnsubscribes(): boolean;
}

namespace AppInfoProvider {
  export interface AccessKey {
    name: string;
    value: string;

    allowDevelMode: boolean;
    allowDataExport: boolean;
    allowContentReadonly: boolean;
    allowContentReadWrite: boolean;
    allowedAudienceProvider: string;
  }
}

export { AppInfoProvider };
