import { injectable } from 'inversify';
import { AppInfoProvider } from './AppInfoProvider';

@injectable()
export class StaticAppInfoProvider implements AppInfoProvider {
  constructor(
    private _id: string,
    private _name: string,
    private _uniqueKey: string,
    private _companyId: number,
    private _keys: Array<AppInfoProvider.AccessKey> = [],
    private _emailAttribute?: string,
    private _enabledForCompanyLevelUnsubscribes: boolean = false
  ) {}

  getId(): string {
    return this._id;
  }

  getName(): string {
    return this._name;
  }

  getUniqueKey(): string {
    return this._uniqueKey;
  }

  getCompanyId(): number {
    return this._companyId;
  }

  getAccessKeys(): Array<AppInfoProvider.AccessKey> {
    return this._keys;
  }

  getEmailAttribute(): string | undefined {
    return this._emailAttribute;
  }

  getEnabledForCompanyLevelUnsubscribes(): boolean {
    return this._enabledForCompanyLevelUnsubscribes;
  }
}
