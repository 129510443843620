import { injectable } from 'inversify';
import { AppInfoProvider } from './AppInfoProvider';

@injectable()
export class MutableAppInfoProvider implements AppInfoProvider {
  constructor(
    private _id: string = 'app123',
    private _name: string = 'App 123',
    private _uniqueKey: string = 'app12345',
    private _companyId: number = 12345,
    private _keys: Array<AppInfoProvider.AccessKey> = [],
    private _emailAttribute: string | undefined = 'email',
    private _enabledForCompanyLevelUnsubscribes: boolean = false
  ) {}

  getId(): string {
    return this._id;
  }

  setId(id: string): this {
    this._id = id;

    return this;
  }

  getUniqueKey(): string {
    return this._uniqueKey;
  }

  setUniqueKey(uniqueKey: string): this {
    this._uniqueKey = uniqueKey;

    return this;
  }

  getName(): string {
    return this._name;
  }

  setName(name: string): this {
    this._name = name;

    return this;
  }

  getCompanyId(): number {
    return this._companyId;
  }

  setCompanyId(id: number): this {
    this._companyId = id;

    return this;
  }

  getAccessKeys(): Array<AppInfoProvider.AccessKey> {
    return this._keys;
  }

  setAccessKeys(keys: Array<AppInfoProvider.AccessKey>): this {
    this._keys = keys;

    return this;
  }

  getEmailAttribute(): string | undefined {
    return this._emailAttribute;
  }

  setEmailAttribute(emailAttribute: string | undefined): this {
    this._emailAttribute = emailAttribute;

    return this;
  }

  getEnabledForCompanyLevelUnsubscribes(): boolean {
    return this._enabledForCompanyLevelUnsubscribes;
  }

  setEnabledForCompanyLevelUnsubscribes(value: boolean): this {
    this._enabledForCompanyLevelUnsubscribes = value;

    return this;
  }
}
