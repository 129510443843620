import { injectable } from 'inversify';
import { Message, Method, rpc, RPCImpl, RPCImplCallback } from 'protobufjs';
import { RpcTransport } from './RpcTransport';

@injectable()
class FakeRpcTransport implements RpcTransport {
  private rpcUrl: string | null = null;
  private request: Uint8Array | null = null;
  private error: Error | null = null;
  private response: Uint8Array | null | undefined = null;

  getRpcImpl({ rpcUrl }: { rpcUrl: string }): RPCImpl {
    this.rpcUrl = rpcUrl;

    return (
      _method: Method | rpc.ServiceMethod<Message<{}>, Message<{}>>,
      requestData: Uint8Array,
      callback: RPCImplCallback
    ): void => {
      this.request = requestData;
      callback(this.error, this.response);
    };
  }

  mockValidServerResponse(response: Uint8Array): void {
    this.response = response;
    this.error = null;
  }

  mockErrorResponse(error: Error): void {
    this.error = error;
    this.response = undefined;
  }

  assertRequestUrl(url: string): void {
    expect(this.rpcUrl).toEqual(url);
  }

  assertMatchingRequest(expectedRequest: Uint8Array): void {
    expect(this.request).toEqual(expectedRequest);
  }

  clear(): void {
    this.rpcUrl = null;
    this.request = null;
    this.response = null;
    this.error = null;
  }
}

export { FakeRpcTransport };
