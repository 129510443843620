import { injectable } from 'inversify';
import { Permission } from '../../constants/Permission';
import { SessionStorageWrapper } from '../../SessionStorageWrapper/SessionStorageWrapper';
import { Dictionary } from '../../types/Dictionary';
import { PermissionsProvider } from './PermissionsProvider';

// NOTE: Ordering and numbers are significant - they match the backend.
enum AccessLevel {
  MANAGE = 0,
  PUBLISH = 1,
  DRAFT = 2,
  VIEW = 3,
  NO_ACCESS = 4
}

// NOTE: Ordering and numbers are significant - they match the backend.
enum Capability {
  MESSAGES = 0,
  VARIABLES = 1,
  INTERFACES = 2,
  AUDIENCES = 3,
  ANALYTICS = 4,
  APP = 5,
  TEAM = 6,
  DATA_CONTROL = 7
}

interface PermissionOverrideInfo {
  permission: Permission;
  enabled: boolean;
}

@injectable()
export class OverrideablePermissionsProvider extends PermissionsProvider {
  private static readonly FORCED_PERMISSIONS_SESSION_KEY = 'leanplum_forced_permissions';

  private sessionStorageWrapper: SessionStorageWrapper<Array<PermissionOverrideInfo>> = new SessionStorageWrapper(
    OverrideablePermissionsProvider.FORCED_PERMISSIONS_SESSION_KEY
  );

  private readonly _overrides: Dictionary<PermissionOverrideInfo>;

  private campaignAccessLevel: number;

  constructor(private capabilities: Array<number>, private isAdmin: boolean) {
    super();

    this._overrides = (this.sessionStorageWrapper.get() || []).reduce<Dictionary<PermissionOverrideInfo>>(
      (overrides, info) => {
        overrides[info.permission] = info;

        return overrides;
      },
      {}
    );

    // For CC, use the "higher" permission of messages & variables
    // (in this case, lower enum value = higher permission level)
    this.campaignAccessLevel = Math.min(
      this.capabilities[Capability.MESSAGES],
      this.capabilities[Capability.VARIABLES]
    );
  }

  hasPermission(permission: Permission): boolean {
    if (this.isAdmin && !!this._overrides[permission]) {
      return this._overrides[permission].enabled;
    } else {
      return this.noOverride(permission);
    }
  }

  setOverride(permission: Permission, enabled: boolean): void {
    if (!this.isAdmin) {
      return;
    }

    this._overrides[permission] = { permission, enabled };
    this.updateSessionStorage();
  }

  deleteOverride(permission: Permission): void {
    if (!this.isAdmin) {
      return;
    }

    delete this._overrides[permission];
    this.updateSessionStorage();
  }

  noOverride(permission: Permission): boolean {
    switch (permission) {
      case Permission.VIEW_AUDIENCE:
        return this.capabilities[Capability.AUDIENCES] <= AccessLevel.VIEW;
      case Permission.DRAFT_APP_ASSETS:
      case Permission.DRAFT_CAMPAIGN:
        return this.campaignAccessLevel <= AccessLevel.DRAFT;
      case Permission.PUBLISH_APP:
        return this.capabilities[Capability.APP] <= AccessLevel.PUBLISH;
      case Permission.PUBLISH_AUDIENCE:
        return this.capabilities[Capability.AUDIENCES] <= AccessLevel.PUBLISH;
      case Permission.PUBLISH_CAMPAIGN:
        return this.campaignAccessLevel <= AccessLevel.PUBLISH;
      case Permission.MANAGE_APP:
        return this.capabilities[Capability.APP] === AccessLevel.MANAGE;
      case Permission.MANAGE_AUDIENCE:
        return this.capabilities[Capability.AUDIENCES] === AccessLevel.MANAGE;
      case Permission.MANAGE_CAMPAIGN:
        return this.campaignAccessLevel === AccessLevel.MANAGE;
      case Permission.MANAGE_DATA_CONTROL:
        return this.capabilities[Capability.DATA_CONTROL] === AccessLevel.MANAGE;
      default:
        return false;
    }
  }

  hasOverrides(): boolean {
    return this.isAdmin && Object.keys(this._overrides).length > 0;
  }

  getOverrides(): Array<[Permission, boolean]> {
    if (!this.isAdmin) {
      return [];
    }

    return Object.values(this._overrides).map(({ permission, enabled }) => [permission, enabled]);
  }

  private updateSessionStorage(): void {
    this.sessionStorageWrapper.set(Object.values(this._overrides));
  }
}
