import { DateTimeConstants } from '../constants/DateTimeConstants';

interface RelativeDate<Unit extends RelativeDate.Unit = RelativeDate.Unit> {
  /**
   * negative for past
   * positive for future
   */
  offset: number;
  unit: Unit;
}

namespace RelativeDate {
  export enum Unit {
    MINUTES = 'minute',
    HOURS = 'hour',
    DAYS = 'day',
    WEEKS = 'week',
    MONTHS = 'month',
    YEARS = 'year'
  }

  const UnitToMillisMap: { [key in Unit]: number } = {
    [Unit.MINUTES]: DateTimeConstants.MILLIS_PER_MINUTE,
    [Unit.HOURS]: DateTimeConstants.MILLIS_PER_HOUR,
    [Unit.DAYS]: DateTimeConstants.MILLIS_PER_DAY,
    [Unit.WEEKS]: DateTimeConstants.MILLIS_PER_WEEK,
    [Unit.MONTHS]: DateTimeConstants.MILLIS_PER_MONTH,
    [Unit.YEARS]: DateTimeConstants.MILLIS_PER_YEAR
  };

  export function toMillis({ offset, unit }: RelativeDate): number {
    return offset * UnitToMillisMap[unit];
  }

  export function formatUnit(offset: number, unit: string): string {
    return Math.abs(offset) === 1 ? unit : `${unit}s`;
  }

  export function format({ offset, unit }: RelativeDate, showDirection: boolean = true): string {
    return [Math.abs(offset), formatUnit(offset, unit), showDirection && offsetDirection(offset)]
      .filter(Boolean)
      .join(' ');
  }

  export function daysAgo(days: number): RelativeDate {
    return {
      offset: days * -1,
      unit: RelativeDate.Unit.DAYS
    };
  }

  export function offsetDirection(offset: number): string {
    return offset > 0 ? 'in the future' : 'ago';
  }
}

export { RelativeDate };
