import { injectable } from 'inversify';
import { Environment } from '../../constants/Environment';
import { EnvironmentProvider } from './EnvironmentProvider';

@injectable()
export class StaticEnvironmentProvider implements EnvironmentProvider {
  private env: Environment;

  public get isLocal(): boolean {
    return this.env === Environment.LOCAL;
  }

  public get isStaging(): boolean {
    return this.env === Environment.STAGING;
  }

  public get isQA(): boolean {
    return this.env === Environment.QA;
  }

  public get isProduction(): boolean {
    return this.env === Environment.PROD;
  }

  constructor(envStr: string) {
    this.env = this.getEnvironmentByString(envStr);
  }

  public getEnvironment(): Environment {
    return this.env;
  }

  private getEnvironmentByString(envStr: string): Environment {
    switch (envStr) {
      case 'prod':
      case 'production':
        return Environment.PROD;

      case 'qa':
        return Environment.QA;

      case 'staging':
        return Environment.STAGING;

      case 'local':
      default:
        return Environment.LOCAL;

    }
  }
}
