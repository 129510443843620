export class DateTimeConstants {
  static readonly MILLIS_PER_SECOND = 1000;
  static readonly SECONDS_PER_MINUTE = 60;
  static readonly MINUTES_PER_HOUR = 60;
  static readonly HOURS_PER_HALF_DAY = 12;
  static readonly HOURS_PER_DAY = 24;
  static readonly DAYS_PER_WEEK = 7;

  // These are controversial, use with caution.
  static readonly DAYS_PER_MONTH = 30;
  static readonly DAYS_PER_YEAR = 365;

  static readonly SECONDS_PER_HOUR = DateTimeConstants.SECONDS_PER_MINUTE * DateTimeConstants.MINUTES_PER_HOUR;
  static readonly SECONDS_PER_DAY = DateTimeConstants.SECONDS_PER_HOUR * DateTimeConstants.HOURS_PER_DAY;
  static readonly MINUTES_PER_DAY = DateTimeConstants.MINUTES_PER_HOUR * DateTimeConstants.HOURS_PER_DAY;
  static readonly ONE_MINUTE_MS = DateTimeConstants.SECONDS_PER_MINUTE * DateTimeConstants.MILLIS_PER_SECOND;
  static readonly ONE_HOUR_MS = DateTimeConstants.MINUTES_PER_HOUR * DateTimeConstants.ONE_MINUTE_MS;

  static readonly MILLIS_PER_MINUTE = DateTimeConstants.MILLIS_PER_SECOND * DateTimeConstants.SECONDS_PER_MINUTE;
  static readonly MILLIS_PER_HOUR = DateTimeConstants.MILLIS_PER_MINUTE * DateTimeConstants.MINUTES_PER_HOUR;
  static readonly MILLIS_PER_DAY = DateTimeConstants.MILLIS_PER_HOUR * DateTimeConstants.HOURS_PER_DAY;
  static readonly MILLIS_PER_WEEK = DateTimeConstants.MILLIS_PER_DAY * DateTimeConstants.DAYS_PER_WEEK;
  static readonly MILLIS_PER_MONTH = DateTimeConstants.MILLIS_PER_DAY * DateTimeConstants.DAYS_PER_MONTH;
  static readonly MILLIS_PER_YEAR = DateTimeConstants.MILLIS_PER_DAY * DateTimeConstants.DAYS_PER_YEAR;

  static readonly TIMEZONE_NICKNAMES: { [key: string]: string } = {
    // Main US timezones. Some exceptional zones are omitted.
    'America/New_York': 'Eastern',
    'America/Chicago': 'Central',
    'America/Denver': 'Mountain',
    'America/Los_Angeles': 'Pacific',
    // Main Canadian timezones. Some small / sparsely populated timezones are omitted.
    'America/Halifax': 'Atlantic',
    'America/Toronto': 'Eastern',
    'America/Winnipeg': 'Central',
    'America/Edmonton': 'Mountain',
    'America/Vancouver': 'Pacific'
  };

  static readonly AM = 'AM';
  static readonly PM = 'PM';
}
