export function formatAmount(num: number): string {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }

  return num.toString();
}

// tslint:disable:no-bitwise
export function rgbaToInt(rgba: string): number {
  const parts: Array<string> = rgba.substring(5, rgba.length - 1).split(',');
  const r: number = parseInt(parts[0]);
  const g: number = parseInt(parts[1]);
  const b: number = parseInt(parts[2]);
  const a: number = parseFloat(parts[3]);

  return b + (g << 8) + (r << 16) + (Math.round(a * 255) << 24);
}

export function intToRgba(value: number): string {
  const b: number = value & 0xff;
  const g: number = (value >> 8) & 0xff;
  const r: number = (value >> 16) & 0xff;
  const a: number = Math.round((10000 * ((value >> 24) & 0xff)) / 255.0) / 10000.0;

  return `rgba(${r},${g},${b},${Math.round(a * 100) / 100})`;
}
// tslint:enable:no-bitwise

export function plural(num: number | string, singular: string, plural: string | null = null): string {
  if (!plural) {
    plural = `${singular}s`;
  }

  return parseInt(`${num}`, 10) === 1 ? singular : plural;
}

export function pluralize(count: number, singular: string): string {
  const plural = `${singular}s`;

  return count === 1 ? singular : plural;
}

export function oneOf<T>(set: Array<T>): (value: unknown) => value is T {
  return (value): value is T => set.includes(value as T);
}

export function subset<T>(set: Array<T>): (values: Array<unknown>) => values is Array<T> {
  return (values): values is Array<T> => values.every((value) => set.includes(value as T));
}

export function escapeHTML(html: string): string {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function isTruthy<T>(value: T | null | undefined | 0 | '' | false): value is T {
  return !!value;
}

export function isNumber(x: string | number): x is number {
  return !isNaN(Number(x));
}

export async function timesAsync(times: number, callback: () => Promise<void>): Promise<void> {
  for (const _ of Array(times).fill(0)) {
    await callback();
  }
}

export function delay(ms: number = 0): Promise<void> {
  return new Promise((r) => setTimeout(r, ms));
}

export function preventDefault(event: Event): void {
  event.preventDefault();
}

export function stopPropagation(event: Event): void {
  event.stopPropagation();
}

export function stopPropagationAndPreventDefault(event: Event): void {
  stopPropagation(event);
  preventDefault(event);
}

export function isKeyOf<T extends object>(value: T, key: string | undefined): key is keyof T & string {
  return !!key && key in value;
}

/**
 * Rounds up a float value up to number of digits provided as a second argument, defaults to 2
 * @param {number} value - the number we want to round
 * @param {number} digits - the digits after floating point to round to
 * @return {number} the result number
 */
export function toDecimal(value: number, digits: number = 2): number {
  const roundedFloat = Math.round(parseFloat(`${value}e${digits}`));

  return Number(`${roundedFloat}e-${digits}`);
}
