import { injectable } from 'inversify';
import { Logger } from '../../diagnostics/Logger/Logger';
import { LatencyEvent } from '../LatencyEvent';
import { Reporter, TrackerDetails } from '../reporter/Reporter';
import { ResourceLoadTracker } from './ResourceLoadTracker';

interface Asset {
  name: string;
  path: string;
}

const BUNDLE_JS: Asset = {
  name: 'Bundle JS',
  path: '/dashboard2/js/bundle.js'
};

interface ResourceLoadTrackerDetails extends TrackerDetails {
  asset: string;
}

@injectable()
class LeanplumResourceLoadTracker implements ResourceLoadTracker {
  private static RESOURCE_ENTRY_TYPE: string = 'resource';

  private assets: Array<Asset> = [BUNDLE_JS];
  private performanceEntries: Array<PerformanceEntry> = [];

  constructor(private reporter: Reporter, private logger: Logger) {}

  track(): void {
    if (!!window.performance) {
      this.performanceEntries = window.performance.getEntriesByType(LeanplumResourceLoadTracker.RESOURCE_ENTRY_TYPE);

      if (this.performanceEntries.length === 0) {
        // Log error instead of throwing.
        this.logger.log('No Resource Entries for this page were found.');

        return;
      }

      this.assets.forEach(
        (asset: Asset): void => {
          const assetEntry: PerformanceResourceTiming | undefined = this.getPerformanceEntryForFile(asset.path);

          if (!!assetEntry) {
            this.reporter.track<ResourceLoadTrackerDetails>(LatencyEvent.RESOURCE_LOAD, {
              asset: asset.name,
              duration: assetEntry.duration
            });
          } else {
            // Log error instead of throwing.
            this.logger.log(`Resource Entry for ${asset.name} was not found.`);
          }
        }
      );
    }
  }

  private getPerformanceEntryForFile(assetPath: string): PerformanceResourceTiming | undefined {
    return this.performanceEntries.find(
      (entry: PerformanceEntry): boolean => {
        return entry.name.includes(assetPath);
      }
    ) as PerformanceResourceTiming;
  }
}

export { LeanplumResourceLoadTracker };
