import { injectable } from 'inversify';
import { SegmentEvent } from 'leanplum-lib-common';

@injectable()
abstract class MemoizedSegmentEventsFetcher {
  abstract fetchForApp(excludeLegacy?: boolean): Promise<Array<SegmentEvent>>;
  abstract reset(): void;
}

export { MemoizedSegmentEventsFetcher };
