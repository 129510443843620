import throttle from 'lodash/throttle';

/**
 * Method Decorator
 * Throttles a class method.
 * @param wait: amount of ms to wait (default: 100)
 */
function Throttled(wait: number = 100): MethodDecorator {
  return <Function>(
    target: object,
    propertyKey: keyof object,
    { value, ...descriptor }: TypedPropertyDescriptor<Function>
  ): TypedPropertyDescriptor<Function> => {
    if (typeof value !== 'function') {
      throw new TypeError('"Throttled" can only decorate class methods.');
    }

    return {
      configurable: true,
      get(this: object): Function {
        const Throttled = throttle(value.bind(this), wait);

        // Memoizing the Throttled method for each instance.
        Reflect.defineProperty(this, propertyKey, {
          ...descriptor,
          value: Throttled
        });

        return Throttled;
      }
    };
  };
}

export { Throttled };
