class EventUtil {
  /**
   * We differentiate between 2 general types of events, based on the event id.
   *  - Custom events: these are defined by LP or clients and have an id that
   *    is human readable and DOES NOT start with a dot.
   *
   *  - Internal events: these are automatically generated for the currently
   *    existing a/b tests, messages and campaigns. They always have a dot at
   *    the beginning, a type identifying character, study id and optional suffix.
   */
  public static getType(id: string): EventUtil.Type {
    return Boolean(id.match(/^\.[mca]/)) ? EventUtil.Type.INTERNAL : EventUtil.Type.CUSTOM;
  }

  /**
   * For internal events returns the proper internal type.
   *
   * @throws When the provided id is not internal event id.
   */
  public static getInternalEventType(id: string): EventUtil.InternalEventType {
    if (EventUtil.isCustomEvent(id)) {
      throw new Error('Unexpected event type - custom events do not have internal types.');
    }

    return id.slice(0, 2).toLowerCase() as EventUtil.InternalEventType;
  }

  /**
   * For internal events returns the study id.
   *
   * @throws When the provided id is not internal event id.
   */
  public static getStudyId(id: string): string {
    if (EventUtil.isCustomEvent(id)) {
      throw new Error('Unexpected event type - custom events do not have a study id.');
    }

    const sepIndex = id.indexOf(' ') !== -1 ? id.indexOf(' ') : undefined;

    return id.slice(2, sepIndex);
  }

  /**
   * For internal events returns the suffix.
   *
   * @throws When the provided id is not internal event id.
   */
  public static getSuffix(id: string): string | undefined {
    if (EventUtil.isCustomEvent(id)) {
      throw new Error('Unexpected event type - custom events do not have a suffix.');
    }

    const sepIndex = id.indexOf(' ') !== -1 ? id.indexOf(' ') : undefined;

    return sepIndex ? id.slice(sepIndex + 1) : undefined;
  }

  public static isInternalEvent(id: string): boolean {
    return EventUtil.getType(id) === EventUtil.Type.INTERNAL;
  }

  public static isCustomEvent(id: string): boolean {
    return EventUtil.getType(id) === EventUtil.Type.CUSTOM;
  }

  /**
   * For internal events returns the proper internal type.
   *
   * @throws When the provided id is not internal event id.
   */
  public static isAbTestEvent(id: string): boolean {
    return EventUtil.isInternalEvent(id) && EventUtil.getInternalEventType(id) === EventUtil.InternalEventType.AB_TEST;
  }

  public static isCampaignEvent(id: string): boolean {
    return EventUtil.isInternalEvent(id) && EventUtil.getInternalEventType(id) === EventUtil.InternalEventType.CAMPAIGN;
  }

  public static isMessageEvent(id: string): boolean {
    return EventUtil.isInternalEvent(id) && EventUtil.getInternalEventType(id) === EventUtil.InternalEventType.MESSAGE;
  }

  public static toString(
    internalEventType: EventUtil.InternalEventType,
    studyId: string | number | Long,
    suffix?: string
  ): string {
    suffix = suffix ? ` ${suffix}` : '';

    return `${internalEventType}${studyId}${suffix}`;
  }
}

namespace EventUtil {
  export enum Type {
    CUSTOM = 'custom',
    INTERNAL = 'internal'
  }

  export enum InternalEventType {
    MESSAGE = '.m',
    CAMPAIGN = '.c',
    AB_TEST = '.a'
  }
}

export { EventUtil };
