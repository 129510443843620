interface ObserverArray {
  __ob__?: {
    dep: {
      notify(): void;
    };
  };
}

/**
 * TimeMachine handler.
 * Renders Vue components after completing undoable actions on Vue's ObserverArray.
 * @param target: ObserverArray
 * @param key: index
 * @param oldValue
 */
function notifyObserverArrayDep(target: ObserverArray, key: keyof ObserverArray, oldValue: unknown): void {
  const { __ob__: ob } = target;

  if (Array.isArray(target) && ob) {
    ob.dep.notify();
  }
}

export { notifyObserverArrayDep };
