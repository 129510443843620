enum LatencyEvent {
  // Resource (js) -> App -> Page
  RESOURCE_LOAD = 'resourceLoad',
  APP_LOAD = 'appLoad',
  PAGE_LOAD = 'pageLoad',

  // RPC
  RPC_REQUEST = 'rpcRequest'
}

export { LatencyEvent };
