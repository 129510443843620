import { injectable } from 'inversify';
import values from 'lodash/values';
import { Whitelist } from '../../constants/Whitelist';
import { WhitelistProvider } from './WhitelistProvider';

@injectable()
export class MutableWhitelistProvider extends WhitelistProvider {
  static allEnabled(): MutableWhitelistProvider {
    return new MutableWhitelistProvider(values<Whitelist>(Whitelist));
  }

  private _whitelists: Set<Whitelist>;
  private _overrides: Set<Whitelist>;

  constructor(whitelists: Array<Whitelist | null> = []) {
    super();
    this.reset(whitelists);
    this._overrides = new Set();
  }

  isWhitelistedFor(whitelist: Whitelist): boolean {
    return this._whitelists.has(whitelist) || this._overrides.has(whitelist);
  }

  isWhitelistedForEvery(whitelists: Array<Whitelist>): boolean {
    return whitelists.every((w) => this.isWhitelistedFor(w));
  }

  enable(whitelist: Whitelist): this {
    this._whitelists.add(whitelist);

    return this;
  }

  disable(whitelist: Whitelist): this {
    this._whitelists.delete(whitelist);

    return this;
  }

  toggle(whitelist: Whitelist, enabled: boolean): this {
    if (enabled) {
      return this.enable(whitelist);
    } else {
      return this.disable(whitelist);
    }
  }

  reset(whitelists: Array<Whitelist | null> = []): this {
    this._whitelists = new Set(whitelists.filter((item) => item !== null) as Array<Whitelist>);

    return this;
  }

  disableOverride(whitelist: Whitelist): void {
    this._overrides.delete(whitelist);
  }

  enableOverride(whitelist: Whitelist): void {
    this._overrides.add(whitelist);
  }

  isOverriden(whitelist: Whitelist): boolean {
    return this._overrides.has(whitelist);
  }

  hasOverrides(): boolean {
    return this._overrides.size > 0;
  }

  getOverrides(): Array<Whitelist> {
    return Array.from(this._overrides);
  }
}
