import { notifyObserverArrayDep as _notifyObserverArrayDep } from './handlers/notifyObserverArrayDep';
import { UndoableAction } from './UndoableAction';

/**
 * Wraps a target into a "Proxy" that tracks and stores mutations applied to the
 * target (and all the objects it refers to, recursively) and allows undo/redo operations on the target.
 * Supports objects, arrays (except for array[index] = undefined), getters, setters.
 */
class TimeMachine<TargetType extends object> {
  private static readonly DEFAULT_LABEL: string = 'Edit';
  private static readonly RESET_LABEL: string = 'Reset';
  private static readonly METADATA_KEY: symbol = Symbol.for('TIME_MACHINE_METADATA');
  private static readonly IS_DEV: boolean = process.env.NODE_ENV === 'development';

  // (Debugging purposes) Holds all instantiated proxies by all instances.
  private static readonly staticInstantiatedReceivers: WeakSet<object> = new WeakSet();
  // (Debugging purposes) Holds all tracked targets by all instances.
  private static readonly staticTrackedTargets: WeakSet<object> = new WeakSet();

  // Holds all instantiated proxies by this instance.
  // Allows for preventing the tracking of an already tracked object.
  private readonly instantiatedReceivers: WeakSet<object> = new WeakSet();
  // Holds all targets that have been already tracked.
  // Allows for caching of created proxies.
  private readonly trackedTargets: WeakMap<object, object> = new WeakMap();

  private readonly target: TargetType;

  private undoActions: Array<UndoableAction> = [];
  private redoActions: Array<UndoableAction> = [];

  // A collection of mutations that have not been batched into an action yet.
  private mutations: Array<UndoableAction.Mutation> = [];

  // Callback that is invoked by every undo/redo action.
  // Default value because it is used in a vue app.
  private undoableActionCompletedHandler:
    | TimeMachine.OnUndoableActionCompletedHandler
    | undefined = _notifyObserverArrayDep;

  public constructor(target: TargetType) {
    this.setHandler = this.setHandler.bind(this);
    this.definePropertyHandler = this.definePropertyHandler.bind(this);

    if (!TimeMachine.isTrackable(target)) {
      throw new Error('Target is not an object.');
    }

    try {
      this.target = this.track(target);
    } catch (error) {
      throw error instanceof RangeError
        ? new RangeError(
            'TimeMachine probably tried to track something that has already been tracked by another TimeMachine.'
          )
        : error;
    }
  }

  /**
   * The target that has been passed into the constructor.
   */
  public get current(): TargetType {
    return this.target;
  }

  public get canUndo(): boolean {
    return this.undoActions.length > 0;
  }

  public get canRedo(): boolean {
    return this.redoActions.length > 0;
  }

  public get lastUndoActionType(): string | null {
    return this.undoActions[this.undoActions.length - 1]?.label ?? null;
  }

  public get lastRedoActionType(): string | null {
    return this.redoActions[this.redoActions.length - 1]?.label ?? null;
  }

  public undo(): void {
    const action: UndoableAction | undefined = this.undoActions.pop();

    if (action === undefined) {
      return;
    }

    this.undoUnsavedMutations();

    action.invoke();
    this.batchMutationsIntoRedo(action.label);
  }

  public redo(): void {
    const action: UndoableAction | undefined = this.redoActions.pop();

    if (action === undefined) {
      return;
    }

    this.mutations = [];

    action.invoke();
    this.batchMutationsIntoUndo(action.label);
  }

  /**
   * Saves the current state of the target so it can be reverted back to it later.
   * Loses all redo actions because target's history is "overwritten".
   */
  public persistState(actionLabel: string = TimeMachine.DEFAULT_LABEL): void {
    if (this.mutations.length === 0) {
      return;
    }

    this.batchMutationsIntoUndo(actionLabel);
    this.redoActions = [];
  }

  /**
   * Stores current state of the target and resets
   * it to initial state without losing history.
   */
  public resetToInitialState(): void {
    if (!this.canUndo) {
      return;
    }

    const actions = this.undoActions.slice().reverse();

    this.undoUnsavedMutations();
    actions.forEach((a) => a.invoke());

    this.batchMutationsIntoUndo(TimeMachine.RESET_LABEL);
  }

  /**
   * Registers a handler on undo/redo actions.
   * @param handler: A callback that accepts 3 arguments:
   *  - target: The tracked target
   *  - key: The name of the property that is about to be modified.
   *  - value: The value that is set by the undo/redo action.
   */
  public onUndoableActionCompleted(handler: TimeMachine.OnUndoableActionCompletedHandler): void {
    this.undoableActionCompletedHandler = handler;
  }

  /**
   * Property class decorator.
   * Disables tracking of this property.
   * @param targetPrototype: class prototype
   * @param key: name of property
   */
  public static Ignore(targetPrototype: object, key: string | symbol): void {
    const metadata = Reflect.getMetadata(TimeMachine.METADATA_KEY, targetPrototype) || {};
    metadata[key] = { ignore: true };

    Reflect.defineMetadata(TimeMachine.METADATA_KEY, metadata, targetPrototype);
  }

  /**
   * Implicitly invoke undo on all leftover mutations.
   * In case you want them to be redoable, call persistState explicitly.
   */
  private undoUnsavedMutations(): void {
    if (this.mutations.length > 0) {
      const mutations = this.mutations.slice().reverse();
      this.mutations = [];

      for (const mutation of mutations) {
        mutation();
      }
    }
  }

  /**
   * Creates a new Undo Action out of all the mutations that have happened after the last batch.
   * @param actionLabel: Name of the new Action.
   */
  private batchMutationsIntoUndo(actionLabel: string): void {
    this.undoActions.push(new UndoableAction(actionLabel, this.mutations));
    this.mutations = [];
  }

  /**
   * Creates a new Redo Action out of all the mutations that have happened after the last batch.
   * @param actionLabel: Name of the new Action.
   */
  private batchMutationsIntoRedo(actionLabel: string): void {
    this.redoActions.push(new UndoableAction(actionLabel, this.mutations));
    this.mutations = [];
  }

  /**
   * Recursively replaces all properties of the target by trackable proxies.
   * @param target: Target that is about to be tracked.
   */
  private track<T extends object>(target: T): T {
    if (TimeMachine.staticInstantiatedReceivers.has(target)) {
      console.warn(`Tried to track a receiver`, target);
    }

    if (TimeMachine.staticTrackedTargets.has(target)) {
      console.warn(`Tried to track an already tracked target`, target);
    }

    // tslint:disable-next-line: forin
    for (const key in target) {
      const nextTarget = target[key];

      // Tracking properties that include __ in its name is (usually) not beneficial.
      if (TimeMachine.isTrackable(nextTarget) && !key.includes('__') && !TimeMachine.isIgnored(target, key)) {
        // Shallow clone each child for more effective caching
        target[key] = this.checkForExistingReceiver(nextTarget) ?? this.track(nextTarget);
      }
    }

    const receiver = this.createReceiver(target);

    this.instantiatedReceivers.add(receiver);
    this.trackedTargets.set(target, receiver);

    if (TimeMachine.IS_DEV) {
      TimeMachine.staticTrackedTargets.add(target);
      TimeMachine.staticInstantiatedReceivers.add(receiver);
    }

    return receiver;
  }

  /**
   * Creates a proxy that traps certain JS operators and provides tracking of property mutations.
   * MDN docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
   * @param target: Target that is about to be tracked.
   */
  private createReceiver<T extends object>(target: T): T {
    // Wrapping the proxy so it can be accessed later on by the delete handler.
    const proxyWrapper: { proxy?: T } = {};

    proxyWrapper.proxy = new Proxy<T>(target, {
      set: this.setHandler,
      defineProperty: this.definePropertyHandler,
      deleteProperty: this.createDeleteHandler(proxyWrapper)
    });

    return proxyWrapper.proxy;
  }

  /**
   * Check if target has an existing associated receiver.
   * @param target
   */
  private checkForExistingReceiver<T extends object>(target: T): T | null {
    if (this.instantiatedReceivers.has(target)) {
      return target;
    }

    if (this.trackedTargets.has(target)) {
      return this.trackedTargets.get(target) as T;
    }

    return null;
  }

  /**
   * Proxy set handler.
   * Mutates the property of the target and pushes a mutation that reverts the action to its previous state.
   * @param target: Target that is about to be tracked. (not proxy)
   * @param key: Name of the property that is going to be assigned.
   * @param newValue: Value that is going to be assigned to the property.
   * @param receiver: Constructed proxy.
   */
  private setHandler<T extends object>(
    target: T,
    key: keyof T,
    newValue: T[keyof T] & object | undefined,
    receiver: T
  ): boolean {
    const oldValue = target[key];

    if (oldValue === newValue) {
      return true;
    }

    // This handles Vue's stupid assignment of array.__proto__.
    if (key === '__proto__') {
      return Reflect.setPrototypeOf(target, newValue || null);
    }

    const isIgnored = TimeMachine.isIgnored(target, key);

    const newValueReceiver =
      TimeMachine.isTrackable(newValue) && !isIgnored
        ? // If the new value is an object we want to track it too.
          this.checkForExistingReceiver(newValue) ?? this.track(newValue)
        : newValue;

    const mutation =
      newValue === undefined ? Reflect.deleteProperty(target, key) : Reflect.set(target, key, newValueReceiver);

    if (isIgnored) {
      return mutation;
    }

    // This fixes an issue with unexpected array.length value after
    // assigning undefined values when invoking mutable Array methods.
    if (Array.isArray(target) && parseInt(key as string) >= 0 && newValue === undefined) {
      const index = parseInt(key as string);
      target.splice(index, 1);
    }

    this.mutations.push(() => {
      if (oldValue === undefined) {
        Reflect.deleteProperty(receiver, key);
      } else {
        Reflect.set(receiver, key, oldValue);
      }

      if (this.undoableActionCompletedHandler) {
        // newValue becomes "old" after oldValue is assigned to the target.
        this.undoableActionCompletedHandler(target, key, newValue);
      }
    });

    return mutation;
  }

  /**
   * Factory for Proxy deleteProperty handler. (delete operator)
   * Needs proxyWrapper because ECMAScript provides receiver (the proxy object) only to get and set handlers.
   * @param proxyWrapper: Object that contains the proxy.
   */
  private createDeleteHandler<T extends object>(proxyWrapper: { proxy?: T }): typeof Reflect.deleteProperty {
    return (target: T, key: keyof T) => this.setHandler(target, key, undefined, proxyWrapper.proxy!);
  }

  /**
   * Proxy defineProperty handler.
   * Composes original getters and setters and ones that track mutations.
   * Enables to track Vue's reactive getter/setter.
   * @param target: Target that is about to be tracked. (not proxy)
   * @param key: Name of the property that is going to be assigned.
   * @param descriptor: PropertyDescriptor
   */
  private definePropertyHandler<T extends object>(target: T, key: string | number | Symbol, descriptor: PropertyDescriptor): boolean {
    const k = key as keyof T;
    if (descriptor.get && descriptor.set && !TimeMachine.isIgnored(target, k)) {
      const { get: targetGet, set: targetSet } = descriptor;
      // State of the property is kept in clojure so get/set can be overridden.
      const local = { [k]: targetGet() };

      return Reflect.defineProperty(target, k, {
        ...descriptor,
        get: () => {
          targetGet();

          return local[k as string];
        },
        set: (value: T[keyof T]) => {
          targetSet(value);

          this.setHandler(local, k as string, targetGet(), local);
        }
      });
    }

    return Reflect.defineProperty(target, k, descriptor);
  }

  private static isTrackable(target: unknown): target is object {
    return target !== null && typeof target === 'object';
  }

  /**
   * Returns true if the property has been marked by TimeMachine.ignore.
   * @param target: class prototype
   * @param key: name of property
   */
  private static isIgnored(target: object, key: string | symbol | number): boolean {
    const metadata = Reflect.getMetadata(TimeMachine.METADATA_KEY, target);

    if (metadata?.[key] === undefined) {
      return false;
    }

    return metadata[key].ignore !== false;
  }
}

namespace TimeMachine {
  export const notifyObserverArrayDep = _notifyObserverArrayDep;

  export type OnUndoableActionCompletedHandler = (
    target: object,
    key: string | number | symbol,
    value: unknown
  ) => void;
}

export { TimeMachine };
