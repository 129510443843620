/**
 * Constants that can be used as identifiers for Dependency Injection when a class hierarchy
 * is not wanted/needed.
 */

export namespace DependencyKey {
  export const AudienceModelFactory = Symbol.for('AudienceModelFactory');
  export const AudienceEmptyMatcherFactory = Symbol.for('AudienceEmptyMatcherFactory');
  export const AudienceProtoMatcherFactory = Symbol.for('AudienceProtoMatcherFactory');
  export const AudienceSelectorModelFactory = Symbol.for('AudienceSelectorModelFactory');
  export const Store = Symbol.for('store');
}
