import { injectable } from 'inversify';
import isEmpty from 'lodash/isEmpty';
import { ApiKeysProvider } from './ApiKeysProvider';

@injectable()
class StaticApiKeysProvider implements ApiKeysProvider {
  constructor(private props: StaticApiKeysProvider.Props) {}

  getGoogleMapsApiKey(): string | null {
    return this.checkAndReturn(this.props.googleMapsApiKey);
  }

  getSegmentIoApiKey(): string | null {
    return this.checkAndReturn(this.props.segmentIoApiKey);
  }

  private checkAndReturn(value?: string | null): string | null {
    return !value || isEmpty(value) ? null : value;
  }
}

namespace StaticApiKeysProvider {
  export interface Props {
    googleMapsApiKey?: string | null;
    segmentIoApiKey?: string | null;
  }
}

export { StaticApiKeysProvider };
