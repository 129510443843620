import { injectable } from 'inversify';
import { AccountInfoProvider } from './AccountInfoProvider';

@injectable()
export class MutableAccountInfoProvider implements AccountInfoProvider {
  constructor(
    private _name: string | null = 'Tester',
    private _email: string = 'test@leanplum.com',
    private _accountId: string = '123',
    private _isLpAdmin: boolean = false,
    private _companyAccountId: string = 'test'
  ) { }

  getName(): string | null {
    return this._name;
  }

  setName(name: string | null): this {
    this._name = name;

    return this;
  }

  getEmail(): string {
    return this._email;
  }

  setEmail(email: string): this {
    this._email = email;

    return this;
  }

  getAccountId(): string {
    return this._accountId;
  }

  setAccountId(accountId: string): this {
    this._accountId = accountId;

    return this;
  }

  isLpAdmin(): boolean {
    return this._isLpAdmin;
  }

  setIsLpAdmin(isLpAdmin: boolean): this {
    this._isLpAdmin = isLpAdmin;

    return this;
  }

  getCompanyAccountId(): string {
    return this._companyAccountId;
  }

  setCompanyAccountId(value: string): this {
    this._companyAccountId = value;

    return this;
  }
}
