import { Container, interfaces } from 'inversify';
import Vue from 'vue';
import { Inject } from 'vue-property-decorator';

const CONTAINER_KEY: string = 'container';

/**
 * Lazy injects the service that is bound to the container provided by Vue.
 * @param serviceIdentifier: identifier of the injectable
 */
function ContainerInject<T>(
  serviceIdentifier: string | symbol | interfaces.Newable<T> | interfaces.Abstract<T>
): PropertyDecorator {
  return (target: Vue, propertyKey: keyof object): void => {
    // Calling inject multiple times is safe and inexpensive.
    Inject()(target, CONTAINER_KEY);

    Reflect.defineProperty(target, propertyKey, {
      get(this: { container: Container; $options: { provide: Function } }): T {
        const provide = this.$options.provide;
        const container = this.container || (provide && provide().container);

        return container.get(serviceIdentifier);
      },
      set(): never {
        throw new Error(`Injected property "${propertyKey}" can not be reassigned because it is readonly.`);
      }
    });
  };
}

export { ContainerInject };
