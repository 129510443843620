import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { get as getCookie } from 'es-cookie';
import { inject, injectable } from 'inversify';
import { AppInfoProvider } from '../providers/appInfo/AppInfoProvider';
import { Proto } from '../types/Proto';
import { AuthTokenType, RequestManager } from './RequestManager';

/**
 * A basic dashboard axios client.
 */
@injectable()
abstract class DashboardAxiosApi {

  @inject(AppInfoProvider) private readonly appInfo: AppInfoProvider;

  public constructor(protected prefix: string = '') {}

  /**
   * Makes a GET request.
   */
  protected async doGet<T = object>(url: string, params?: { [key: string]: Object }): Promise<AxiosResponse<T>> {
    const requestConfig: AxiosRequestConfig = { ...(await this.getDefaultRequestConfig()), params };

    return Axios.get<T>(`${this.prefix}${url}`, requestConfig).then(this._handleErrors);

  }

  /**
   * Makes a POST request.
   */
  protected async doPost<T = object>(
    url: string,
    data: Proto,
    extraHeaders?: { [k: string]: string }
  ): Promise<AxiosResponse<T>> {
    const requestConfig: AxiosRequestConfig = { ...(await this.getDefaultRequestConfig()) };
    if (extraHeaders) {
      requestConfig.headers = { ...requestConfig.headers, ...extraHeaders };
    }

    return Axios.post(`${this.prefix}${url}`, data.toJSON(), requestConfig).then(this._handleErrors);
  }

  private _handleErrors(response: AxiosResponse): AxiosResponse {
    if (response.status !== 200) {
      let additionalInfo = '';
      if (('code' in response.data) && ('message' in response.data)) {
        // We received an error response
        additionalInfo = `\nError code: ${response.data.code}, Error message: ${response.data.message}`;
      }

      throw new Error(`Server responded with error:\nHTTP ${response.status}${additionalInfo}`);
    }

    return response;
  }

  protected async getDefaultRequestConfig(): Promise<AxiosRequestConfig> {
    const jwtAuthToken = await RequestManager.getAuthToken(AuthTokenType.APPLICATION, Number(this.appInfo.getId()));

    return {
      headers: {
        _c: getCookie('c'), // csrf token
        'X-Use-Camel-Case-Proto-Models': 'true', // Require camel case in proto models.
        Authorization: `Bearer ${jwtAuthToken}`
      }
    };
  }
}

export { DashboardAxiosApi };
