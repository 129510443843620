import { injectable } from 'inversify';
import { Whitelist } from '../../constants/Whitelist';
import { Memoize } from '../../decorators/Memoize';
import { SessionStorageWrapper } from '../../SessionStorageWrapper/SessionStorageWrapper';
import { WhitelistProvider } from './WhitelistProvider';

@injectable()
export class OverrideableWhitelistProvider extends WhitelistProvider {
  private static readonly FORCE_ENABLED_WHITELISTS_SESSION_KEY = 'leanplum_force_enabled_whitelists';

  private overridenWhitelists: Set<Whitelist>;
  private sessionStorageWrapper: SessionStorageWrapper<Array<Whitelist>> = new SessionStorageWrapper(
    OverrideableWhitelistProvider.FORCE_ENABLED_WHITELISTS_SESSION_KEY
  );

  constructor(
    private whitelists: Array<string> = [],
    private isAdmin: boolean = false
  ) {
    super();
    this.overridenWhitelists = new Set(this.sessionStorageWrapper.get() || []);
  }

  @Memoize()
  isWhitelistedFor(whitelist: Whitelist): boolean {
    if (this.isAdmin && this.isOverriden(whitelist)) {
      return true;
    }

    return this.whitelists.indexOf(whitelist) > -1;
  }

  isWhitelistedForEvery(whitelists: Array<Whitelist>): boolean {
    return whitelists.every((w) => this.isWhitelistedFor(w));
  }

  enableOverride(whitelist: Whitelist): void {
    if (!this.isAdmin || this.overridenWhitelists.has(whitelist)) {
      return;
    }

    this.overridenWhitelists.add(whitelist);
    this.updateSessionStorage();
  }

  disableOverride(whitelist: Whitelist): void {
    if (!this.isAdmin || !this.overridenWhitelists.has(whitelist)) {
      return;
    }

    this.overridenWhitelists.delete(whitelist);
    this.updateSessionStorage();
  }

  isOverriden(whitelist: Whitelist): boolean {
    return this.isAdmin && this.overridenWhitelists.has(whitelist);
  }

  hasOverrides(): boolean {
    return this.isAdmin && this.overridenWhitelists.size > 0;
  }

  getOverrides(): Array<Whitelist> {
    return this.isAdmin ? Array.from(this.overridenWhitelists.values()) : [];
  }

  private updateSessionStorage(): void {
    this.sessionStorageWrapper.set(Array.from(this.overridenWhitelists.values()));
  }
}
