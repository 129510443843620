import { injectable } from 'inversify';
import { Route } from 'vue-router';
import { PageLoadTracker } from './PageLoadTracker';

@injectable()
class FakePageLoadTracker implements PageLoadTracker {
  private routeFromLastRequest: Route;

  track(currentRoute: Route): void {
    this.routeFromLastRequest = currentRoute;
  }

  get routeFromPreviousRequest(): Route {
    return this.routeFromLastRequest;
  }
}

export { FakePageLoadTracker };
