import Vue, { VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { ContainerInject } from '../../../decorators/ContainerInject';
import { PageLoadTracker } from '../PageLoadTracker';

@Component({ name: 'PageLoadComponent' })
class PageLoadComponent extends Vue {
  @ContainerInject(PageLoadTracker) private pageLoadTracker: PageLoadTracker;

  mounted(): void {
    this.$nextTick(
      (): void => {
        this.pageLoadTracker.track(this.$route);
      }
    );
  }

  render(): VNode | Array<VNode> {
    return <div />;
  }
}

export { PageLoadComponent };
