enum State {
  UNINITIALIZED,
  PENDING,
  RESOLVED,
  REJECTED
}

type Fetchable<T> = Resolved<T> | Pending | Uninitialized | Rejected;

interface Resolved<T> {
  value: T;
  state: State.RESOLVED;
}

interface Pending {
  state: State.PENDING;
  promise?: Promise<void>;
}

interface Uninitialized {
  state: State.UNINITIALIZED;
}

interface Rejected {
  state: State.REJECTED;
  error: Error;
}

namespace Fetchable {
  export function resolved<T>(value: T): Resolved<T> {
    return { value, state: State.RESOLVED };
  }

  export function pending(promise?: Promise<unknown>): Pending {
    return { state: State.PENDING, promise: promise?.then() };
  }

  export function uninitialized(): Uninitialized {
    return { state: State.UNINITIALIZED };
  }

  export function rejected(error: Error): Rejected {
    return { error, state: State.REJECTED };
  }

  export function isUninitialized<T>(fetchable: Fetchable<T>): fetchable is Uninitialized {
    return fetchable.state === State.UNINITIALIZED;
  }

  export function isResolved<T>(fetchable: Fetchable<T>): fetchable is Resolved<T> {
    return fetchable.state === State.RESOLVED;
  }

  export function isPending(fetchable: Fetchable<unknown>): fetchable is Pending {
    return fetchable.state === State.PENDING;
  }

  export function valueOrDefault<T>(fetchable: Fetchable<T> | undefined, defaultValue: T): T {
    return fetchable !== undefined && isResolved(fetchable) ? fetchable.value : defaultValue;
  }

  export function promise<T>(fetchable: Fetchable<T>): Promise<void> | undefined {
    return fetchable.state === State.PENDING ? fetchable.promise : undefined;
  }

  export function resolvedOrUninitialized<T>(value: T | undefined): Resolved<T> | Uninitialized {
    return value === undefined ? uninitialized() : resolved(value);
  }
}

export type { Resolved };
export { Fetchable };
