import { injectable } from 'inversify';
import { Logger } from '../../diagnostics/Logger/Logger';
import { TrackerDetails } from '../reporter/Reporter';
import { PerformanceTracker } from './PerformanceTracker';

@injectable()
class LeanplumPerformanceTracker implements PerformanceTracker {
  private static START_MARKER: string = '-start';
  private static END_MARKER: string = '-end';

  constructor(private logger: Logger) {}

  start(method: string): void {
    if (!!window.performance) {
      window.performance.mark(`${method}${LeanplumPerformanceTracker.START_MARKER}`);
    }
  }

  end(method: string): TrackerDetails | null {
    if (!window.performance) {
      return null;
    }

    const startMarker: string = `${method}${LeanplumPerformanceTracker.START_MARKER}`;
    const endMarker: string = `${method}${LeanplumPerformanceTracker.END_MARKER}`;

    window.performance.mark(endMarker);

    try {
      window.performance.measure(method, startMarker, endMarker);
    } catch (e) {
      this.logger.log('Error during performance measurement', e);

      return null;
    }

    const performanceEntries: Array<PerformanceEntry> = window.performance.getEntriesByName(method);
    const duration: number = performanceEntries[0].duration;

    window.performance.clearMarks(startMarker);
    window.performance.clearMarks(endMarker);
    window.performance.clearMeasures(method);

    return { duration };
  }
}

export { LeanplumPerformanceTracker };
