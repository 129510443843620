// tslint:disable:no-console no-any
import { injectable } from 'inversify';
import { Logger } from './Logger';

@injectable()
class ConsoleLogger extends Logger {
  log(...messages: Array<any>): void {
    console.log(...messages);
  }
}

export { ConsoleLogger };
