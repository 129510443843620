import { injectable } from 'inversify';
import { Permission } from '../../constants/Permission';

@injectable()
export abstract class PermissionsProvider {
  abstract hasPermission(permission: Permission): boolean;

  /**
   * Set Permission override for Leanplum Admins.
   * @param permission
   */
  abstract setOverride(permission: Permission, enabled: boolean): void;

  /**
   * Deletes Permission override for Leanplum Admins.
   * @param permission
   */
  abstract deleteOverride(permission: Permission): void;

  /**
   * Returns the non-overriden value of the permission.
   * @param permission
   */
  abstract noOverride(permission: Permission): boolean;

  /**
   * Returns true if there are any permissions overriden.
   */
  abstract hasOverrides(): boolean;

  /**
   * Returns the overriden permissions.
   */
  abstract getOverrides(): Array<[Permission, boolean]>;
}
