import { injectable } from 'inversify';
import values from 'lodash/values';
import { Permission } from '../../constants/Permission';
import { Dictionary } from '../../types/Dictionary';
import { PermissionsProvider } from './PermissionsProvider';

@injectable()
export class MutablePermissionsProvider extends PermissionsProvider {
  static allEnabled(): MutablePermissionsProvider {
    return new MutablePermissionsProvider(values<Permission>(Permission));
  }

  private _permissions: Set<Permission>;
  private _overrides: Dictionary<{ permission: Permission, enabled: boolean }>;

  constructor(permissions: Array<Permission | null> = [], private isAdmin: boolean = true) {
    super();
    this.reset(permissions);
    this._overrides = {};
  }

  hasPermission(permission: Permission): boolean {
    if (this.isAdmin && !!this._overrides[permission]) {
      return this._overrides[permission].enabled;
    }

    return this._permissions.has(permission);
  }

  enable(permission: Permission): this {
    this._permissions.add(permission);

    return this;
  }

  disable(permission: Permission): this {
    this._permissions.delete(permission);

    return this;
  }

  toggle(permission: Permission, enabled: boolean): this {
    if (enabled) {
      return this.enable(permission);
    } else {
      return this.disable(permission);
    }
  }

  reset(permissions: Array<Permission | null> = []): this {
    this._permissions = new Set(permissions.filter((item) => item !== null) as Array<Permission>);

    return this;
  }

  setOverride(permission: Permission, enabled: boolean): void {
    this._overrides[permission] = { permission, enabled };
  }

  deleteOverride(permission: Permission): void {
    delete this._overrides[permission];
  }

  noOverride(permission: Permission): boolean {
    return this._permissions.has(permission);
  }

  hasOverrides(): boolean {
    return Object.keys(this._overrides).length > 0;
  }

  getOverrides(): Array<[Permission, boolean]> {
    return Object.values(this._overrides).map(({ permission, enabled }) => [permission, enabled]);
  }
}
