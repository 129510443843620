/**
 * Encapsulates a collection of mutations (functions that cause side-effects)
 * and is able to invoke them in correct order.
 */
class UndoableAction {
  constructor(public readonly label: string, private readonly mutations: Array<UndoableAction.Mutation>) {}

  /**
   * Calls all mutations in reverse (natural) order.
   */
  public invoke(): void {
    for (const mutation of this.mutations.slice().reverse()) {
      mutation();
    }
  }
}

namespace UndoableAction {
  /**
   * Function that causes side-effects.
   */
  export type Mutation = () => void;
}

export { UndoableAction };
